import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import Main from '~components/layouts/Main'
import HeroSection from '~components/layouts/marketing/HeroSection'
import SEO from '~components/seo'

const HelpHeading = styled.h3`
  font-size: 16px;
  line-height: 1.25;
  font-weight: 700;
  color: ${scssVars.darkBrown};
`

const heroBanner = 'https://leatherspa.imgix.net/Customer-Services/Individual-Pages/Payment-Service_Image.jpg?fit=scale'

function PaymentServicePage () {
  return (
    <Layout>
      <SEO
        title="Pay for Services"
        pathname="/customer-services/payment"
        description="Pay for your Shoe and Handbag repair service"
      />
      <Main>
        <HeroSection title={'Pay For Services'} fluid={false}
          breadcrumb={{ title: 'At Your Service', path: '/customer-services' }}
          mobileImg={heroBanner}
          desktopImg={heroBanner}
        >
          <>
            <h2>Discover all the ways Leather Spa can simplify the repair experience.</h2>
            <p>
              Please use this secure payment form to make a payment towards services received and/or merchandise at any of our locations. Please make sure to enter the correct payment amount, provide us with all the required information and to enter your billing address and zip/postal code of the credit or debit card that you are using.
            </p>
            <p>
              <strong>Contact Us:</strong>&nbsp;
              If you have any questions regarding our payment services, please email us at <a className="text-link" href="mailto:hello@leatherspa.com">hello@leatherspa.com</a> or contact the store where you dropped off your items.
            </p>
            <a href="https://old.leatherspa.com/customer-service/payment" className="btn btn-primary px-5" target="_blank" rel="noreferrer">
              Pay For Services
            </a>
          </>
        </HeroSection>
        <section className="mt-5 bg-clr-cararra pt-4">
          <Container>
            <Row>
              <Col lg={4}>
                <HelpHeading>Contact Us</HelpHeading>
                <p>If you have any questions regarding our pick-up service, please email us at repairs@leatherspa.com or check out our Operational Policies.</p>
              </Col>
              <Col lg={8}>
                <HelpHeading>Accessibility</HelpHeading>
                <Row>
                  <Col lg={6}>
                    <p>If you are having any difficulty using this page or service, please contact the Help Desk at <a className="text-link" href="mailto:repairs@leatherspa.com">info@leatherspa.com</a> or <a className="text-link" href="tel:+17183929220">(718) 392-9220</a>.</p>
                  </Col>
                  <Col lg={6}>
                    <p>Please identify the specific problems you have encountered and we will address the issue or assist you over the phone.</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Main>
    </Layout>
  )
}

export default PaymentServicePage
